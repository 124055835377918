.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: inherit;
    align-items: center;
    justify-content: space-evenly;
}

.form__title {
    font-size: 2.4rem;
    font-weight: 800;
    margin-bottom: 1rem;
    display: block;
    width: 100%;
    text-align: center;
    background: rgba(255,255,255,0.6);
    color: var(--text-dark);
    border-radius: 0.5rem;
}

.form__label {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem 2.5rem;
}

.form__name-input {
    font-size: 1.3rem;
    font-weight: bold;
    margin-right: .3rem;
    color: #4f4f4f;
}

.form__input {
    width: 100%;
    /* max-width: 305px; */
    min-width: 300px;
    font-size: 1rem;
    font-weight: 300;
    outline: none;
    /* border: 1px solid rgba(255,255,255,.2); */
    border: 1px solid var(--tertiaryColor);
    background: transparent;
    padding: 8px 10px;
    /* border-radius: 6px; */
    color: #4f4f4f;
    box-shadow: inset 0 0 25px rgba(0,0,0,.2);
}

.form__opt {
    background-color: #fff;
}

.form__button {
    width: 100%;
    max-width: 150px;
    font-size: 1rem;
    font-weight: 300;
    outline: none;
    border: 1px solid var(--tertiaryColor);
    background: linear-gradient(-35deg, var(--tertiaryColor) 30%, var(--secundary-color) 70%);
    padding: 8px 10px;
    border-radius: 6px;
    color: #fff;
    box-shadow: inset 0 0 25px rgba(0,0,0,.2);
    cursor: pointer;
    margin: 1rem 0;
}

.form__button:active {
    transform: scale(.9);
}

.form_view-password {
    position: relative;
    display: flex;
}

.form__view-icon {
    position: absolute;
    top: 17%;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.form__span {
    color: #4f4f4f;
    font-size: 1rem;
    font-weight: bold;
}

.form__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* TODO: Mejorar link */
.form__span-link {
    color: #4f4f4f;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
}

.form__error {
    display: inline-block;
    width: 100%;
    color: var(--text-error);
}

/* XL */
@media (max-width: 1268px) {
    .form__input{
        max-width: 450px;
    }

    .form_view-password{
        width: 100%;
    }
}

/* XXL */
@media (min-width: 1201px) and (max-width: 1400px) {
    .raffle__button {
        font-size: 1rem;
        font-weight: bold;
    }
}