.swiper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  max-height: calc(100vh - 62.66px);
  min-height: calc(100vh - 62.66px);
  background: var(--primary-color);
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: var(--primary-color);
  height: 100%;
}

.swiper-pagination {
  position: absolute;
  bottom: 10%;
  z-index: 10;
  display: flex;
}

.slider__caption {
  width: 15px;
  height: 7px;
  background-color: var(--fourth-color);
  margin: 0 .2rem;
  border-radius: 1rem;
  cursor: pointer;
  border: 1px solid var(--tertiary-color);
}

.slider__caption--active,
.swiper-pagination-bullet-active {
    width: 25px;
    background-color: var(--primary-color); 
    cursor: default;
    transition: 1s;
}

.carousel__slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 56px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 2rem;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.swiper-button-prev-custom, .swiper-button-next-custom {
  position: absolute;
  top: 50%;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}
.swiper-button-next-custom {
  right: 45px;
  left: auto;
}
.swiper-button-prev-custom {
  right: auto;
  left: 15px;
}

.swiper-arrow-size {
  font-size: 3rem;
  font-weight: bold;
}

.button-arrow-custom:after {
  content: "";
  background: #00374838;
  display: block;
  position: absolute;
  opacity: 0;
  transition: all 0.8s;
  width: 60px;
  height: 100vh;
  left: -16px;
  /* top: -8px; */
  /* border-radius: 50%; */
}

.button-arrow-custom:active:after,
.button-arrow-custom:hover:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}